<template>
  <div v-padding:top="46">
    <van-nav-bar title="基本信息" fixed left-arrow @click-left="goBack">
    </van-nav-bar>
    <van-form @submit="onSubmit" :show-error="false">
      <van-cell-group>
        <van-field
          name="picker"
          v-model="formData.lng"
          label="经度"
          placeholder="请选择经度"
        />
        <van-field
          name="lat"
          v-model="formData.lat"
          label="纬度"
          placeholder="请选择纬度"
        />
        <van-field
          name="address"
          rows="2"
          autosize
          type="textarea"
          v-model="formData.address"
          show-word-limit
          label="详细地址"
          placeholder="请输入详细地址"
        />
        <van-radio-group v-model="formData.controlLevel">
          <van-cell-group title="控制类别">
            <van-cell
              :title-style="{ textAlign: 'right', paddingRight: '20px' }"
              title="重点排污单位"
              clickable
              @click="formData.controlLevel = 1"
            >
              <template #right-icon>
                <van-radio :name="1" />
              </template>
            </van-cell>
            <van-cell
              :title-style="{ textAlign: 'right', paddingRight: '20px' }"
              title="一般排污单位"
              clickable
              @click="formData.controlLevel = 2"
            >
              <template #right-icon>
                <van-radio :name="2" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <van-field
          name="industryId"
          readonly
          clickable
          v-model="formData.industryName"
          label="行业类别"
          placeholder="请选择行业类别"
          @click="showIndustrySheet = true"
        />
        <van-field
          readonly
          clickable
          name="gmtProduction"
          v-model="formData.gmtProduction"
          label="投产时间"
          placeholder="请选择投产时间"
          @click="calendarShow = true"
        />
        <van-field
          name="contactName"
          v-model="formData.contactName"
          label="联系人姓名"
          placeholder="请填写联系人姓名"
          :rules="[{ required: true, message: '请填写联系人姓名' }]"
        />
        <van-field
          name="contactTel"
          v-model="formData.contactTel"
          label="联系人电话"
          placeholder="请填写联系人电话"
          :rules="[{ required: true, message: '请填写联系人电话' }]"
        />
        <van-field
          name="corporate"
          v-model="formData.corporate"
          label="法人姓名"
          placeholder="请填写法人姓名"
          :rules="[{ required: true, message: '请填写法人姓名' }]"
        />
        <van-field
          name="corporateTel"
          v-model="formData.corporateTel"
          label="法人电话"
          placeholder="请填写法人电话"
          :rules="[{ required: true, message: '请填写法人电话' }]"
        />
        <van-field
          name="remark"
          rows="2"
          autosize
          type="textarea"
          v-model="formData.remark"
          show-word-limit
          label="备注"
          placeholder="请输入备注"
        />
      </van-cell-group>
      <van-row>
        <van-col span="24">
          <van-button
            block
            type="primary"
            native-type="submit"
            :loading="submitLoading"
          >
            保存
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <van-action-sheet
      description="选择行业类别"
      v-model="showIndustrySheet"
      :actions="industryActions"
      @select="onIndustrySelect"
      close-on-click-action
      cancel-text="取消"
    />
    <van-calendar
      v-model="calendarShow"
      type="single"
      :min-data="minData"
      @confirm="onConfirm"
      :allow-same-day="true"
      :style="{ height: '62%' }"
      color="#378aed"
    />
  </div>
</template>

<script>
import axios from "axios";
import { Toast } from "vant";

export default {
  components: {},
  props: {},
  data() {
    return {
      minData: new Date("2020/01/01"),
      enterpriseId: localStorage.getItem("enterpriseId"),
      formData: {
        lng: "", // 经度
        lat: "", // 纬度
        address: "", // 详细地址
        controlLevel: 1, // 控制级别
        industryId: "", // 行业id
        industryName: "", // 行业名称
        gmtProduction: "", // 投产时间
        contactName: "", // 联系人姓名
        corporate: "", // 法人姓名
        contactTel: "", // 联系人电话
        corporateTel: "", // 法人电话
        remark: "", // 备注
      },
      showControlLevelSheet: false,
      showIndustrySheet: false,
      industryActions: [],
      submitLoading: false,
      calendarShow: false,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getEnterInfo();
  },
  methods: {
    onConfirm(date) {
      this.calendarShow = false;
      this.formData.gmtProduction = this.$moment(date).format("YYYY-MM-DD");
    },
    onIndustrySelect(item) {
      this.formData.industryId = item.id;
      this.formData.industryName = item.industryName;
    },
    onSubmit() {
      this.submitLoading = true;
      const data = {
        id: this.enterpriseId,
        lng: this.formData.lng,
        lat: this.formData.lat,
        address: this.formData.address,
        controlLevel: this.formData.controlLevel,
        industryId: this.formData.industryId,
        gmtProduction: this.formData.gmtProduction
          ? this.$moment(this.formData.gmtProduction).format("YYYY-MM-DD") +
            " 00:00:00"
          : "",
        contactName: this.formData.contactName,
        corporate: this.formData.corporate,
        contactTel: this.formData.contactTel,
        corporateTel: this.formData.corporateTel,
        remark: this.formData.remark,
      };
      this.$api.setting
        .putEnterpriseInfo(data)
        .then((res) => {
          if (res.data.state == 0) {
            Toast.success("修改成功");
            this.goBack();
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    // 获取企业信息和行业列表
    getEnterInfo() {
      const data = {
        id: this.enterpriseId,
      };
      axios
        .all([
          this.$api.setting.getEnterpriseInfo(data),
          this.$api.setting.getIndustryListAll(),
        ])
        .then(
          axios.spread((res1, res2) => {
            if (res1.data.state == 0) {
              const _data = res1.data.data;
              this.formData = {
                lng: String(
                  typeof _data.basicInfo == "undefined"
                    ? ""
                    : _data.basicInfo.lng
                ),
                lat: String(
                  typeof _data.basicInfo == "undefined"
                    ? ""
                    : _data.basicInfo.lat
                ),
                address:
                  typeof _data.basicInfo == "undefined"
                    ? ""
                    : _data.basicInfo.address,
                controlLevel:
                  typeof _data.basicInfo == "undefined"
                    ? ""
                    : _data.basicInfo.controlLevel,
                industryId:
                  typeof _data.basicInfo == "undefined"
                    ? ""
                    : _data.basicInfo.industryId,
                gmtProduction: _data?.basicInfo?.gmtProduction
                  ? this.$moment(_data.basicInfo.gmtProduction).format(
                      "YYYY-MM-DD"
                    )
                  : "",
                contactName:
                  typeof _data.basicInfo == "undefined"
                    ? ""
                    : _data.basicInfo.contactName,
                corporate:
                  typeof _data.basicInfo == "undefined"
                    ? ""
                    : _data.basicInfo.corporate,
                contactTel:
                  typeof _data.basicInfo == "undefined"
                    ? ""
                    : _data.basicInfo.contactTel,
                corporateTel:
                  typeof _data.basicInfo == "undefined"
                    ? ""
                    : _data.basicInfo.corporateTel,
                remark:
                  typeof _data.basicInfo == "undefined"
                    ? ""
                    : _data.basicInfo.remark,
                industryName: "",
              };
            }
            if (res2.data.state == 0) {
              this.industryActions = res2.data.data || [];
              this.industryActions.map((item) => {
                item.name = item.industryName;
              });
              const temp = this.industryActions.filter((item) => {
                if (item.id == this.formData.industryId) {
                  return item;
                }
              });
              if (temp.length)
                this.formData.industryName = temp[0].industryName;
            }
          })
        );
    },
  },
};
</script>

<style lang="less" scoped></style>
